$(function(){
    var headerHeight;
    initMobileMenu();
    stickyHeader();

    if ($('#popUpsContainer').length) {
        setTimeout(function () {
            var items = [];
            $('#popUpsContainer').find('.uno-mfp-popup').each(function () {
                items.push({
                    src: $(this)
                });
            });
            $.magnificPopup.open({
                items: items,
                type: 'inline',
                gallery: {
                    enabled: true
                }
            });
        }, 2500);
    }

    $('.btnCloseMagnific').click(function(){
        $.magnificPopup.close();
    });

    initSearchBar();


    function initSearchBar() {
        $('#search').click(function(){
            var $searchBar = $('#searchBar');
            if($(this).data('opened') != 1) {
                $(this).data('opened', 1);
                $(this).addClass('active');
                $(this).find('.fa').removeClass('fa-search').addClass('fa-times');
                $searchBar.fadeIn();
                $searchBar.find('input[type="text"]').focus();
            }else{
                $(this).data('opened', 0);
                $(this).removeClass('active');
                $(this).find('.fa').removeClass('fa-times').addClass('fa-search');
                $searchBar.fadeOut();
            }
        });
    }

    $('#menu nav li:has(ul)').doubleTapToGo();

    var backTop = $("#backToTop");
    backTop.click(function () {
        $('body, html').animate({
            scrollTop: 0
        }, 800);
        return false;
    });
});

function stickyHeader(){
    // var scroll = $(window).scrollTop();
    // headerHeight = $(".header-wrapper").outerHeight(true);
    //
    // var $menu = $('header');
    // if (scroll > 1) {
    //     $menu.addClass('sticky');
    // } else {
    //     $menu.removeClass('sticky');
    // }

    $(".header-menu").css("top", $(".header-bottom").outerHeight());
}

$(window).on('scroll',function(){
    // console.log($(window).scrollTop(), headerHeight);
    // if($(window).scrollTop() > headerHeight) {
    //     $('header').addClass('sticky');
    // } else {
    //     $('header').removeClass('sticky');
    // }
});

function initMobileMenu() {
    if ($(window).width() < 991) {
        $(".header-weather").empty();
    }

    var menu = $('.header-menu nav').clone(),
        mobileMenu = $('#mobile-menu');
        mobileMenu.append(menu.html()).mmenu({
        offCanvas: {
            position: "right"
        },
        navbar: {
            title: ""
        },
        navbars: [
            {
                position: "top",
                content: [
                    $('#searchBar').clone()
                ],
                height: 2
            },
            {
                position: "top",
                content: [
                    '<div class="header-weather hidden-sm hidden-xs"><a class="weatherwidget-io" href="https://forecast7.com/en/1d61110d38/kuching/" data-font="Ubuntu" data-mode="Current" data-days="3" >Kuching, Sarawak, Malaysia</a> <script>!function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0];if(!d.getElementById(id)){js=d.createElement(s);js.id=id;js.src=\'https://weatherwidget.io/js/widget.min.js\';fjs.parentNode.insertBefore(js,fjs);}}(document,\'script\',\'weatherwidget-io-js\');</script> </div>'
                ],
                height: 3
            },
            {
                position: "bottom",
                content: [

                ],
                height: 1
            }
        ]
    });
}
